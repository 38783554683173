const ORIGINS_WHITELIST = [
    '.dynamics.com',
    '.crmlivetie.com',
    '.dynamics-int.com',
    '.msauth.net',
    '.mcas.ms',
    '.msocdn.com',
    '.skype.com',
    '.skype.net',
    '.microsoftonline.com',
    '.sharepointonline.com',
    '.sharepoint.com',
    '.office.com',
    '.office.net',
    '.msftauth.net',
    '.office365.com',
    '.microsoft.com',
    '.live.com',
    '.azure.net',
    '.powerapps.com',
    '.cihosting.blob.core.windows.net',
    '.spoppe-b.azureedge.net',
    '.spoprod-a.akamaihd.net',
    '.render.percy.local',
    '.proxyme.percy.io'
];

export function isAllowedOrigin(origin) {
    return origin && ORIGINS_WHITELIST.some((domain) => origin.toLowerCase().endsWith(domain));
}
